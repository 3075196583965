import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../static/img/logo.png";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  color: theme.palette.text.secondary,
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}));

export default function SessionFail() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Container>
      <Item>
        <Box
          component="img"
          src={Logo}
          alt="Company Logo"
          sx={{
            width: isSmallScreen ? "30%" : "10%",
            transition: "width 0.3s ease-in-out",
          }}
        />
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", marginBottom: 2 }}
        >
          NO TIENES UNA SESIÓN ACTIVA
        </Typography>
        <Button
          component={Link}
          variant="contained"
          to="/login"
          sx={{ marginTop: 2 }}
        >
          Ingresar
        </Button>
      </Item>
    </Container>
  );
}
