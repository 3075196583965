import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { format, parseISO } from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartPercentProfits = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Percent",
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        // Consulta para profits globales
        const globalResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/percent-profits-pmfxes?pagination[pageSize]=1000000000`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt")}`,
            },
          }
        );

        const globalData = globalResponse.data.data;

        // Consulta para profits individuales
        const individualResponse = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/api/percent-users-profits?filters[user][id][$eq]=${Cookies.get(
            "id"
          )}&pagination[pageSize]=1000000000`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt")}`,
            },
          }
        );

        const individualData = individualResponse.data.data;

        // Creamos un objeto para acumular los porcentajes por mes (globales e individuales)
        const monthlyData = {};

        // Procesamos los profits globales
        globalData.forEach((item) => {
          const closingDate = parseISO(item.attributes.closing_date);
          const monthYear = format(closingDate, "MMMM yyyy");
          const percent = item.attributes.percent;

          if (monthlyData[monthYear]) {
            monthlyData[monthYear] += percent;
          } else {
            monthlyData[monthYear] = percent;
          }
        });

        // Procesamos los profits individuales
        individualData.forEach((item) => {
          const closingDate = parseISO(item.attributes.closing_date);
          const monthYear = format(closingDate, "MMMM yyyy");
          const percent = item.attributes.percent;

          if (monthlyData[monthYear]) {
            monthlyData[monthYear] += percent;
          } else {
            monthlyData[monthYear] = percent;
          }
        });

        // Ordenamos las etiquetas y datos
        const sortedLabels = Object.keys(monthlyData).sort(
          (a, b) =>
            new Date(`${a} 01`).getTime() - new Date(`${b} 01`).getTime()
        );
        const sortedPercents = sortedLabels.map((label) => monthlyData[label]);

        setChartData({
          labels: sortedLabels,
          datasets: [
            {
              label: "Porcentaje por Mes",
              data: sortedPercents,
              backgroundColor: "#ffb95f",
              borderColor: "#ffb95f",
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchChartData();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top", display: false },
      title: {
        display: false,
        text: "Percent Profits Acumulado por Mes",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options} width={100} height={60} />;
};

export default ChartPercentProfits;
