import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../../static/img/logo.png";
import Rocket from "../../static/img/rocket.png";
import FormLogin from "./components/FormLogin";

const Background = styled(Box)(({ theme }) => ({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "linear-gradient(135deg, #1d575b 0%, #092f32 100%)",
}));

const LoginBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: 10,
  padding: theme.spacing(4),
  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)", // Sombra suave
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export default function Login({ sessionActive, setSessionActive }) {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (sessionActive) {
      navigate("/dashboard");
    }
  }, [sessionActive, navigate]);

  return (
    <Background>
      <Container>
        <Grid container spacing={4} alignItems="center">
          {/* Rocket image section */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: "center",
              display: { xs: "none", md: "block" },
            }}
          >
            <Box
              component="img"
              src={Rocket}
              alt="Rocket Image"
              sx={{
                width: "80%",
                animation: "float 3s ease-in-out infinite",
              }}
            />
          </Grid>

          {/* Login form section */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <LoginBox>
              <Box
                component="img"
                src={Logo}
                alt="Company Logo"
                sx={{
                  width: isSmallScreen ? "40%" : "25%",
                  marginBottom: 2,
                }}
              />
              <Typography
                variant="h4"
                sx={{
                  color: "#1d575b",
                  fontWeight: "bold",
                  marginBottom: 3,
                  textAlign: "center",
                  fontFamily: "'Roboto', sans-serif", // Tipografía más estilizada
                }}
              >
                Iniciar sesión
              </Typography>
              <FormLogin setSessionActive={setSessionActive} />
            </LoginBox>
          </Grid>
        </Grid>
      </Container>
    </Background>
  );
}
