import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios";
import Cookies from "js-cookie";

import Login from "./pages/Login/Login";
// import Registro from "./pages/Registro";
import DashboardPage from "./pages/Dashboard/Dashboard";
import Fondo from "./pages/Dashboard/Fondo/Fondo";
import Funds from "./pages/Dashboard/Funds/Funds";

import SpinnerPage from "./components/SpinnerPage";

// Tema personalizado
const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: { main: "#ffb95f" },
    secondary: { main: "#1d575b" },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

function App() {
  const [sessionActive, setSessionActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Manejo de la sesión
  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/me`,
          { headers: { Authorization: `Bearer ${Cookies.get("jwt")}` } }
        );
        if (response.status === 200) {
          setSessionActive(true);
        }
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };
    fetchSession();
  }, []);

  // Manejo del estado de carga (spinner)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {isLoading && <SpinnerPage />}

      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Login
                  sessionActive={sessionActive}
                  setSessionActive={setSessionActive}
                />
              }
            />
            <Route
              path="/login"
              element={
                <Login
                  sessionActive={sessionActive}
                  setSessionActive={setSessionActive}
                />
              }
            />
            {/* <Route
              path="/registro"
              element={<Registro sessionActive={sessionActive} />}
            /> */}
            <Route
              path="/dashboard"
              element={
                <DashboardPage
                  sessionActive={sessionActive}
                  setSessionActive={setSessionActive}
                />
              }
            >
              <Route index element={<Fondo />} />
              <Route path="home" element={<Fondo />} />
              <Route path="funds" element={<Funds />} />
              <Route path="*" element={<DashboardPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
