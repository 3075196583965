import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function FormLogin({ setSessionActive }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    identifier: "",
    password: "",
  });
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/local`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ` + process.env.REACT_APP_API_TOKEN_EVENT,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const dataRes = await response.json();

      if (response.status === 200 && dataRes.user.confirmed_admin) {
        Cookies.set("jwt", dataRes.jwt);
        Cookies.set("id", dataRes.user.id);
        setSessionActive(true);
        setAlert({
          show: true,
          type: "success",
          message: "Te has registrado exitosamente.",
        });
        navigate("/dashboard");
      } else if (response.status === 400) {
        setAlert({
          show: true,
          type: "error",
          message: "Correo o contraseña incorrectos",
        });
      } else {
        setAlert({
          show: true,
          type: "error",
          message: "No estás verificado",
        });
      }
    } catch (error) {
      setAlert({
        show: true,
        type: "error",
        message: "Error en el servidor. Inténtalo más tarde.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              type="email"
              required
              autoComplete="off"
              value={data.identifier}
              onChange={(e) => setData({ ...data, identifier: e.target.value })}
              aria-label="Email"
              error={alert.show && alert.type === "error"}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Contraseña"
              type="password"
              required
              autoComplete="off"
              value={data.password}
              onChange={(e) => setData({ ...data, password: e.target.value })}
              aria-label="Contraseña"
              error={alert.show && alert.type === "error"}
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Ingresar
              </Button>
            )}
          </Grid>
        </Grid>
      </form>

      {alert.show && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Alert severity={alert.type}>{alert.message}</Alert>
        </Grid>
      )}
    </>
  );
}
